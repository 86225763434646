import { FC } from 'react';
import { Tooltip, Box } from 'components/primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'

type Props = {
  content: any
}

export const QuestionMark: FC<Props> = ({ content }) => {
  return (
    <Box css={{ cursor: 'pointer' }}>
      <Tooltip
        sideOffset={4}
        content={content}
      >
        <FontAwesomeIcon width={16} icon={faCircleQuestion} />
      </Tooltip>
    </Box>
  )
}
