import { FC } from 'react'
import { Text } from 'components/primitives'
import { CollectionGroup } from 'utils/collections'

type Props = {
  group: CollectionGroup
}

export const GroupDescription: FC<Props> = ({ group }) => {
  switch (group) {
    case 'characters':
      return (
        <Text style="body3" css={{ display: 'block' }}>
          Official character NFTs in the Forgotten Runes ecosystem
        </Text>
      )
    case 'relics':
      return (
        <Text style="body3" css={{ display: 'block' }}>
          Official relics of the Forgotten Runes ecosystem
        </Text>
      )
    case 'game':
      return (
        <Text style="body3" css={{ display: 'block' }}>
          Official NFTs for the Forgotten Runiverse Game
        </Text>
      )
    case 'community':
      return (
        <Text style="body3" css={{ display: 'block' }}>
          Community projects - "Ours over official" means that FRWC loves and
          supports community-driven works
        </Text>
      )
    case 'cards':
      return (
        <Text style="body3" css={{ display: 'block' }}>
          Community Created Collectible Cards
        </Text>
      )
    default:
      return null
  }
}
